#horizontal-menu {
	background-color: $horizontal-menu-background-color;
	border-bottom: 1px solid $horizontal-menu-border-color;
	.navbar-toggle {
		color: #aaabae;
		border: 1px solid #aaabae;
		font-size: 22px;
		line-height: 22px;
		padding: 5px 6px;
		margin: 5px 8px;
		@include transition(all 200ms ease-in-out);
		&:hover {
			color: #fff;
			border-color: #fff;
		}
	}
	.navbar-brand {
		padding: 5px 20px;
		a {
			overflow: hidden;
			display: inline-block;
			img {
				width: 130px;
			}
		}
	}
	.navbar-left {
		margin-left: 10px;
	}
	.navbar-right {
		margin-right: 10px;
	}
	.dropdown-menu {
		border: 1px solid rgba(0,0,0,0.15);
		background-color: #eee;
		margin: 0 0 0 -1px;
		padding: 4px 0;
		@include border-radius(0 0 2px 2px);
		a{
			i {
				margin-right: 5px;
			}
			&:hover {
				background-color: #bebebe;
			}
		}
		.divider {
			height: 1px;
			margin: 6px 0;
			overflow: hidden;
			background-color: rgba(0,0,0,.15);
		}
	}
}

@media (max-width: 768px) {
	#horizontal-menu {
		.navbar-brand {
			padding: 5px 10px;
		}
		.navbar-left, .navbar-right {
			display: none;
		}
	}
}
