@mixin font-smoothing($value: on){
	@if $value == on {
		-moz-osx-font-smoothing: grayscale;
		 -webkit-font-smoothing: antialiased;
	}
	@else {
		-moz-osx-font-smoothing: auto;
		 -webkit-font-smoothing: subpixel-antialiased;
	}
}

@mixin border-radius($radii){
	-webkit-border-radius: $radii;
	   -moz-border-radius: $radii;
	        border-radius: $radii;
	-webkit-background-clip: padding;
	   -moz-background-clip: padding;
	        background-clip: padding-box;
}

@mixin box-shadow($value...){
	-webkit-box-shadow: $value;
	   -moz-box-shadow: $value;
	     -o-box-shadow: $value;
	        box-shadow: $value;
}

@mixin box-sizing($value){
	-webkit-box-sizing: $value;
	   -moz-box-sizing: $value;
	        box-sizing: $value;
}

@mixin label-variant($color) {
	background-color: $color;
	&[href] {
		&:hover,
		&:focus {
			background-color: darken($color, 10%);
		}
	}
}

@mixin transform($args...){
	-webkit-transform: $args;
	   -moz-transform: $args;
	    -ms-transform: $args;
	     -o-transform: $args;
	        transform: $args;
}

@mixin auth-social-button($bg, $bg-i){
	background-color: $bg;

	i {
		background-color: $bg-i;
	}

	&:hover {
		background-color: lighten($bg, 80%);
		i {
			background-color: darken($bg-i, 5%);
		}
	}
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/*
@mixin ribbon-variant($color, $text){
	&:before{
		content: "";
		display: block;
		position: absolute;
		width: 0px;
		height: 0px;
		top: 10px;
		right: -25px;
		z-index: 10;
		width: 100px;
		height: 20px;
		text-align: center;
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
		// Rhombus
		border-width: 0 20px 20px 20px;
		border-style: solid;
		border-color: transparent transparent $color transparent;
	}

	// This does the text "featured" and the gradient that makes it look curved
	&:after{
		z-index: 10;
		display: block;
		position: absolute;
		content: $text;
		color: #000;
		top: 8px;
		right: -48px;
		width: 140px;
		height: 28px;
		padding-top: 5px;
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		text-shadow: 0 1px rgba(255,255,255,0.2);
	}
}*/
