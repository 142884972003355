#temp-font {
	font-family: $univers-font;
	h3 {
		font-family: $univers-cond-font;
		font-weight: 500;
		text-transform: uppercase;
		color: #333;
	}
}

h3.panel-title {
	font-weight: 500;
	font-size: 18px;
}

.white {
	background-color: #fff;
	padding: 30px;
}

h1.page-header {
	margin-top: 5px;
	font-size: 18px;
}

.panel-option {
	float: right;
	font-size: 14px;
	.checkbox label {
		font-family: $univers-font;
		font-weight: 500 !important;
	}
}

.div {
	clear: both;
	margin: 0px 0 25px;
	border-bottom: 1px solid #eee;
	padding: 15px 0 0;
}

.control-label-lg {
	padding-top: 14px !important;
}

.radio label, .checkbox label {
	font-weight: bold;
}

.btn-lg, .btn-group-lg>.btn {
	font-family: $univers-cond-font;
	font-weight: 700;
	font-size: 28px;
	text-transform: uppercase;
	padding: 6px 12px;
}

.panel-dark > .panel-heading {
    color: #fff;
    background-color: #333;
    border-color: #333;
	.panel-title {
		font-size: 22px;
	}
}


#dealer-check {
	margin: 0 10px 40px;
	zoom: 1.3;
}

.required:after {
	content: " *";
	font-size: 16px;
	line-height: 14px;
	font-weight: 700;
	color: #dc1500;
}


.premium-footnote {
	background-color: #FFF!important;
	p {
		font-style: italic;
		margin-bottom: 5px;
		font-size: 12px;
	}
}


sup {
	font-weight: bold;
	font-size: 95%;
	top: -.25em;
	display: inline-block;
	margin: 0 2px;
}

.fine-print {
	font-family: sans-serif;
	margin-top: 25px;
	margin-bottom: 5px;
	color: #777;
	font-size: 11px;
	display: inline-block;
	sup {
		margin-right: 3px;
		margin-left: 0;
		display: inline-block;
		font-size: 90%;
	}
}

.help-block {
	&:empty {
		margin: 0;
	}
}

.red {
	color: $mecum-red;
}

#terms-of-service {
	width: 100%;
	height: 250px;
	overflow-y: scroll;
	border: 1px solid #ddd;
	padding: 10px;
	background-color: #fafafa;
	margin-top: 30px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {

	h1.page-header {
		font-size: 24px;
	}

	h3.panel-title {
		font-weight: 500;
		font-size: 24px;
	}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
	h1.page-header {
		font-size: 30px;
	}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
	h1.page-header {
		font-size: 40px;
	}
}
