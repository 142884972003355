.help-block {
	color: #aaa;
	font-size: 12px;
	margin-bottom: 0;
}

.form-divider {
	margin: 5px -15px 20px;
	border-bottom: 1px solid #ddd;
}


span.form-control {
	border: none;
	@include box-shadow(none);
	font-weight: bold;
	padding: 7px 3px;
}

.form-control-feedback {
	top: 9px;
}

.form-horizontal .has-feedback-select .form-control-feedback {
	right: 23px;
}

.form-group-no-margin {
	margin: 0 !important;
	label {
		margin: 0 !important;
	}
}

.radio {
	margin-left: 10px;
	input[type=radio] {
		margin-top: 2px;
	}
}

.cc-types {
	list-style: none;
	margin: 0 5px 25px;
	padding: 0;
	color: #787878;
	font-size: 32px;
	li {
		display: inline-block;
		margin-right: 5px;
	}
}

input:-webkit-autofill { -webkit-box-shadow:0 0 0 500px white inset; }

.form-horizontal {
	.panel-body {
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
