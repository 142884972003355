.well {
	&.well-danger {
		@extend .bg-danger;
	}
	.form-control-feedback {
		top: -6px;
		right: -5px !important;
		font-size: 32px;
	}
	input[type=checkbox] {
		margin-right: 10px;
	}
}
