$font-family: 'Roboto', sans-serif !default;

$body-background-color: #fff !default;

$sidebar-menu-width: 250px !default;
$sidebar-menu-collapsed-width: 60px !default;
$sidebar-menu-background-color: #333 !default;
$sidebar-menu-text-color: #aaabae !default;
$sidebar-menu-border-color: #555 !default;
$sidebar-menu-active-background-color: #222 !default;
$sidebar-menu-active-text-color: #fff !default;
$sidebar-submenu-background-color: #222 !default;

$horizontal-menu-height: 50px !default;
$horizontal-menu-background-color: #333 !default;
$horizontal-menu-border-color: #555 !default;

$content-background-color: #fff !default;

$univers-font: 'Univers', arial, sans-serif;
$univers-cond-font: 'UniversCond', arial, sans-serif;
$univers-cond-bold-font: 'UniversCond', arial, sans-serif;

// Mecum colors
$mecum-dk-gray	:	#2C2C2C;
$mecum-md-gray 	:	#575757;
$mecum-lt-gray	:	#D4D2D3;
$mecum-red		:	#DE1F26;
$mecum-yellow	:	#FEEE0B;
$mecum-hairline	:	#9D9D9D;

$image-path: '/dist/images/' !default;
