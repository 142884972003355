.center {
	text-align: center;
}

.block {
	display: block;
}

.middle {
	vertical-align: middle !important;
}

input[type=number] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}


.row.row-no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.row.row-no-gutters > [class^="col-"],
.row.row-no-gutters > [class*=" col-"] {
	padding-right: 0;
	padding-left: 0;
}

.row.row-neg-margins {
	margin-right: -10px;
	margin-left: -10px;
}

@media (min-width: $screen-md){
	.row.row-neg-margins {
		margin-right: -15px;
		margin-left: -15px;
	}
}
