h1.page-header {
	i {
		margin-right: 10px;
		&.entypo {
			vertical-align: top;
		}
	}
}


h1 {
	font-family: $univers-font;
	font-weight: 700;
	font-size: 36px;
	text-transform: uppercase;
	.btn {
		font-family: 'Roboto', sans-serif;
		text-transform: none;
	}
}

h2 {
	font-family: $univers-font;
	font-weight: 500;
	font-size: 30px;
	text-transform: uppercase;
}

h3 {
	font-family: $univers-font;
	text-transform: uppercase;
	margin: 35px 0 15px;
	font-size: 22px;
}


h4 {
	font-family: $univers-font;
	text-transform: uppercase;
	margin: 20px 0 5px;
}

.panel-heading {
	padding: 10px 10px;

	h3.panel-title {
		font-family: $univers-cond-font;
		font-weight: 500;
		text-transform: uppercase;
	}
}


.pane-header {
	font-family: $univers-cond-font;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 28px;
	margin-bottom: 15px;
}

h4.section-header {
	font-family: $univers-font;
	font-weight: 500;
	font-size: 22px;
	text-transform: uppercase;
}
