html, body {
	//width: 100%;
	//height: 100%;
}

body {
	font-family: $font-family;
	background-color: $body-background-color;
	perspective: none;
}

.page-wrapper {
	background-color: $sidebar-menu-background-color;
	position: relative;
	width: 100%;
	height: 100%;
}

#horizontal-menu {
	margin-bottom: 0;
	height: $horizontal-menu-height;
}

#page-content {
	margin-left: $sidebar-menu-width;
	margin-top: $horizontal-menu-height;
	position: relative;
	padding: 20px;
	background-color: $content-background-color;
}


/*@media (min-width: $screen-sm-min){
	#horizontal-menu {
		border-width: 0 0 1px 0;
 	 	@include border-radius(0);
	}
}*/

@media (max-width: $screen-xs-max){
	#sidebar-menu {
		display: none;
	}
	#page-content {
		margin-left: 0;
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
	#sidebar-menu {
		width: $sidebar-menu-collapsed-width;
		#main-menu{
			> li {
				text-align: center;
				a {
					&:before {
						display: none;
					}
					padding: 20px 10px;
					span {
						display: none;
					}
					i {
						margin: 0 !important;
					}
				}
				ul {
					display: none;
				}
			}
		}
	}
	#page-content {
		margin-left: $sidebar-menu-collapsed-width;
	}
}

@media (min-width: $screen-sm-min){
	.navbar-collapse.collapse {
		display: none !important;
	}
}
