$entypo-font-path:     "../fonts" !default;
$entypo-css-prefix:    entypo !default;
$entypo-version:       "3.0" !default;

@font-face {
	font-family: "Entypo";
	src:url('#{$entypo-font-path}/entypo.eot?v=#{$entypo-version}');
	src:url('#{$entypo-font-path}/entypo.eot?#iefix&v=#{$entypo-version}') format('embedded-opentype'),
		url('#{$entypo-font-path}/entypo.woff?v=#{$entypo-version}') format('woff'),
		url('#{$entypo-font-path}/entypo.ttf?v=#{$entypo-version}') format('truetype'),
		url('#{$entypo-font-path}/entypo.svg#entypo?v=#{$entypo-version}') format('svg');
	font-weight: normal;
	font-style: normal;

}

[class^='#{$entypo-css-prefix}-']:before,
[class*=' #{$entypo-css-prefix}-']:before {
	font-family: "Entypo" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1em;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

 	display: inline-block;
	text-decoration: inherit;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
}

.#{$entypo-css-prefix}-adjust:before{ content: "a"; }
.#{$entypo-css-prefix}-air:before{ content: "b"; }
.#{$entypo-css-prefix}-alert:before{ content: "c"; }
.#{$entypo-css-prefix}-archive:before{ content: "d"; }
.#{$entypo-css-prefix}-arrow-combo:before{ content: "e"; }
.#{$entypo-css-prefix}-arrows-ccw:before{ content: "f"; }
.#{$entypo-css-prefix}-attach:before{ content: "g"; }
.#{$entypo-css-prefix}-attention:before{ content: "h"; }
.#{$entypo-css-prefix}-back:before{ content: "i"; }
.#{$entypo-css-prefix}-back-in-time:before{ content: "j"; }
.#{$entypo-css-prefix}-bag:before{ content: "k"; }
.#{$entypo-css-prefix}-basket:before{ content: "l"; }
.#{$entypo-css-prefix}-battery:before{ content: "m"; }
.#{$entypo-css-prefix}-behance:before{ content: "n"; }
.#{$entypo-css-prefix}-bell:before{ content: "o"; }
.#{$entypo-css-prefix}-block:before{ content: "p"; }
.#{$entypo-css-prefix}-book:before{ content: "q"; }
.#{$entypo-css-prefix}-book-open:before{ content: "r"; }
.#{$entypo-css-prefix}-bookmark:before{ content: "s"; }
.#{$entypo-css-prefix}-bookmarks:before{ content: "t"; }
.#{$entypo-css-prefix}-box:before{ content: "u"; }
.#{$entypo-css-prefix}-briefcase:before{ content: "v"; }
.#{$entypo-css-prefix}-brush:before{ content: "w"; }
.#{$entypo-css-prefix}-bucket:before{ content: "x"; }
.#{$entypo-css-prefix}-calendar:before{ content: "y"; }
.#{$entypo-css-prefix}-camera:before{ content: "z"; }
.#{$entypo-css-prefix}-cancel:before{ content: "A"; }
.#{$entypo-css-prefix}-cancel-circled:before{ content: "B"; }
.#{$entypo-css-prefix}-cancel-squared:before{ content: "C"; }
.#{$entypo-css-prefix}-cc:before{ content: "D"; }
.#{$entypo-css-prefix}-cc-by:before{ content: "E"; }
.#{$entypo-css-prefix}-cc-nc:before{ content: "F"; }
.#{$entypo-css-prefix}-cc-nc-eu:before{ content: "G"; }
.#{$entypo-css-prefix}-cc-nc-jp:before{ content: "H"; }
.#{$entypo-css-prefix}-cc-nd:before{ content: "I"; }
.#{$entypo-css-prefix}-cc-pd:before{ content: "J"; }
.#{$entypo-css-prefix}-cc-remix:before{ content: "K"; }
.#{$entypo-css-prefix}-cc-sa:before{ content: "L"; }
.#{$entypo-css-prefix}-cc-share:before{ content: "M"; }
.#{$entypo-css-prefix}-cc-zero:before{ content: "N"; }
.#{$entypo-css-prefix}-ccw:before{ content: "O"; }
.#{$entypo-css-prefix}-cd:before{ content: "P"; }
.#{$entypo-css-prefix}-chart-area:before{ content: "Q"; }
.#{$entypo-css-prefix}-chart-bar:before{ content: "R"; }
.#{$entypo-css-prefix}-chart-line:before{ content: "S"; }
.#{$entypo-css-prefix}-chart-pie:before{ content: "T"; }
.#{$entypo-css-prefix}-chat:before{ content: "U"; }
.#{$entypo-css-prefix}-check:before{ content: "V"; }
.#{$entypo-css-prefix}-clipboard:before{ content: "W"; }
.#{$entypo-css-prefix}-clock:before{ content: "X"; }
.#{$entypo-css-prefix}-cloud:before{ content: "Y"; }
.#{$entypo-css-prefix}-cloud-thunder:before{ content: "Z"; }
.#{$entypo-css-prefix}-code:before{ content: "0"; }
.#{$entypo-css-prefix}-cog:before{ content: "1"; }
.#{$entypo-css-prefix}-comment:before{ content: "2"; }
.#{$entypo-css-prefix}-compass:before{ content: "3"; }
.#{$entypo-css-prefix}-credit-card:before{ content: "4"; }
.#{$entypo-css-prefix}-cup:before{ content: "5"; }
.#{$entypo-css-prefix}-cw:before{ content: "6"; }
.#{$entypo-css-prefix}-database:before{ content: "7"; }
.#{$entypo-css-prefix}-db-shape:before{ content: "8"; }
.#{$entypo-css-prefix}-direction:before{ content: "9"; }
.#{$entypo-css-prefix}-doc:before{ content: "!"; }
.#{$entypo-css-prefix}-doc-landscape:before{ content: '"'; }
.#{$entypo-css-prefix}-doc-text:before{ content: "#"; }
.#{$entypo-css-prefix}-doc-text-inv:before{ content: "$"; }
.#{$entypo-css-prefix}-docs:before{ content: "%"; }
.#{$entypo-css-prefix}-dot:before{ content: "&"; }
.#{$entypo-css-prefix}-dot-2:before{ content: "'"; }
.#{$entypo-css-prefix}-dot-3:before{ content: "("; }
.#{$entypo-css-prefix}-down:before{ content: ")"; }
.#{$entypo-css-prefix}-down-bold:before{ content: "*"; }
.#{$entypo-css-prefix}-down-circled:before{ content: "+"; }
.#{$entypo-css-prefix}-down-dir:before{ content: ","; }
.#{$entypo-css-prefix}-down-open:before{ content: "-"; }
.#{$entypo-css-prefix}-down-open-big:before{ content: "."; }
.#{$entypo-css-prefix}-down-open-mini:before{ content: "/"; }
.#{$entypo-css-prefix}-down-thin:before{ content: ":"; }
.#{$entypo-css-prefix}-download:before{ content: ";"; }
.#{$entypo-css-prefix}-dribbble:before{ content: "<"; }
.#{$entypo-css-prefix}-dribbble-circled:before{ content: "="; }
.#{$entypo-css-prefix}-drive:before{ content: ">"; }
.#{$entypo-css-prefix}-dropbox:before{ content: "?"; }
.#{$entypo-css-prefix}-droplet:before{ content: "@"; }
.#{$entypo-css-prefix}-erase:before{ content: "["; }
.#{$entypo-css-prefix}-evernote:before{ content: "]"; }
.#{$entypo-css-prefix}-export:before{ content: "^"; }
.#{$entypo-css-prefix}-eye:before{ content: "_"; }
.#{$entypo-css-prefix}-facebook:before{ content: "`"; }
.#{$entypo-css-prefix}-facebook-circled:before{ content: "{"; }
.#{$entypo-css-prefix}-facebook-squared:before{ content: "|"; }
.#{$entypo-css-prefix}-fast-backward:before{ content: "}"; }
.#{$entypo-css-prefix}-fast-forward:before{ content: "~"; }
.#{$entypo-css-prefix}-feather:before{ content: "\\"; }
.#{$entypo-css-prefix}-flag:before{ content: "\e000"; }
.#{$entypo-css-prefix}-flash:before{ content: "\e001"; }
.#{$entypo-css-prefix}-flashlight:before{ content: "\e002"; }
.#{$entypo-css-prefix}-flattr:before{ content: "\e003"; }
.#{$entypo-css-prefix}-flickr:before{ content: "\e004"; }
.#{$entypo-css-prefix}-flickr-circled:before{ content: "\e005"; }
.#{$entypo-css-prefix}-flight:before{ content: "\e006"; }
.#{$entypo-css-prefix}-floppy:before{ content: "\e007"; }
.#{$entypo-css-prefix}-flow-branch:before{ content: "\e008"; }
.#{$entypo-css-prefix}-flow-cascade:before{ content: "\e009"; }
.#{$entypo-css-prefix}-flow-line:before{ content: "\e00a"; }
.#{$entypo-css-prefix}-flow-parallel:before{ content: "\e00b"; }
.#{$entypo-css-prefix}-flow-tree:before{ content: "\e00c"; }
.#{$entypo-css-prefix}-folder:before{ content: "\e00d"; }
.#{$entypo-css-prefix}-forward:before{ content: "\e00e"; }
.#{$entypo-css-prefix}-gauge:before{ content: "\e00f"; }
.#{$entypo-css-prefix}-github:before{ content: "\e010"; }
.#{$entypo-css-prefix}-github-circled:before{ content: "\e011"; }
.#{$entypo-css-prefix}-globe:before{ content: "\e012"; }
.#{$entypo-css-prefix}-google-circles:before{ content: "\e013"; }
.#{$entypo-css-prefix}-gplus:before{ content: "\e014"; }
.#{$entypo-css-prefix}-gplus-circled:before{ content: "\e015"; }
.#{$entypo-css-prefix}-graduation-cap:before{ content: "\e016"; }
.#{$entypo-css-prefix}-heart:before{ content: "\e017"; }
.#{$entypo-css-prefix}-heart-empty:before{ content: "\e018"; }
.#{$entypo-css-prefix}-help:before{ content: "\e019"; }
.#{$entypo-css-prefix}-help-circled:before{ content: "\e01a"; }
.#{$entypo-css-prefix}-home:before{ content: "\e01b"; }
.#{$entypo-css-prefix}-hourglass:before{ content: "\e01c"; }
.#{$entypo-css-prefix}-inbox:before{ content: "\e01d"; }
.#{$entypo-css-prefix}-infinity:before{ content: "\e01e"; }
.#{$entypo-css-prefix}-info:before{ content: "\e01f"; }
.#{$entypo-css-prefix}-info-circled:before{ content: "\e020"; }
.#{$entypo-css-prefix}-instagrem:before{ content: "\e021"; }
.#{$entypo-css-prefix}-install:before{ content: "\e022"; }
.#{$entypo-css-prefix}-key:before{ content: "\e023"; }
.#{$entypo-css-prefix}-keyboard:before{ content: "\e024"; }
.#{$entypo-css-prefix}-lamp:before{ content: "\e025"; }
.#{$entypo-css-prefix}-language:before{ content: "\e026"; }
.#{$entypo-css-prefix}-lastfm:before{ content: "\e027"; }
.#{$entypo-css-prefix}-lastfm-circled:before{ content: "\e028"; }
.#{$entypo-css-prefix}-layout:before{ content: "\e029"; }
.#{$entypo-css-prefix}-leaf:before{ content: "\e02a"; }
.#{$entypo-css-prefix}-left:before{ content: "\e02b"; }
.#{$entypo-css-prefix}-left-bold:before{ content: "\e02c"; }
.#{$entypo-css-prefix}-left-circled:before{ content: "\e02d"; }
.#{$entypo-css-prefix}-left-dir:before{ content: "\e02e"; }
.#{$entypo-css-prefix}-left-open:before{ content: "\e02f"; }
.#{$entypo-css-prefix}-left-open-big:before{ content: "\e030"; }
.#{$entypo-css-prefix}-left-open-mini:before{ content: "\e031"; }
.#{$entypo-css-prefix}-left-thin:before{ content: "\e032"; }
.#{$entypo-css-prefix}-level-down:before{ content: "\e033"; }
.#{$entypo-css-prefix}-level-up:before{ content: "\e034"; }
.#{$entypo-css-prefix}-lifebuoy:before{ content: "\e035"; }
.#{$entypo-css-prefix}-light-down:before{ content: "\e036"; }
.#{$entypo-css-prefix}-light-up:before{ content: "\e037"; }
.#{$entypo-css-prefix}-link:before{ content: "\e038"; }
.#{$entypo-css-prefix}-linkedin:before{ content: "\e039"; }
.#{$entypo-css-prefix}-linkedin-circled:before{ content: "\e03a"; }
.#{$entypo-css-prefix}-list:before{ content: "\e03b"; }
.#{$entypo-css-prefix}-list-add:before{ content: "\e03c"; }
.#{$entypo-css-prefix}-location:before{ content: "\e03d"; }
.#{$entypo-css-prefix}-lock:before{ content: "\e03e"; }
.#{$entypo-css-prefix}-lock-open:before{ content: "\e03f"; }
.#{$entypo-css-prefix}-login:before{ content: "\e040"; }
.#{$entypo-css-prefix}-logo-db:before{ content: "\e041"; }
.#{$entypo-css-prefix}-logout:before{ content: "\e042"; }
.#{$entypo-css-prefix}-loop:before{ content: "\e043"; }
.#{$entypo-css-prefix}-magnet:before{ content: "\e044"; }
.#{$entypo-css-prefix}-mail:before{ content: "\e045"; }
.#{$entypo-css-prefix}-map:before{ content: "\e046"; }
.#{$entypo-css-prefix}-megaphone:before{ content: "\e047"; }
.#{$entypo-css-prefix}-menu:before{ content: "\e048"; }
.#{$entypo-css-prefix}-mic:before{ content: "\e049"; }
.#{$entypo-css-prefix}-minus:before{ content: "\e04a"; }
.#{$entypo-css-prefix}-minus-circled:before{ content: "\e04b"; }
.#{$entypo-css-prefix}-minus-squared:before{ content: "\e04c"; }
.#{$entypo-css-prefix}-mixi:before{ content: "\e04d"; }
.#{$entypo-css-prefix}-mobile:before{ content: "\e04e"; }
.#{$entypo-css-prefix}-monitor:before{ content: "\e04f"; }
.#{$entypo-css-prefix}-moon:before{ content: "\e050"; }
.#{$entypo-css-prefix}-mouse:before{ content: "\e051"; }
.#{$entypo-css-prefix}-music:before{ content: "\e052"; }
.#{$entypo-css-prefix}-mute:before{ content: "\e053"; }
.#{$entypo-css-prefix}-network:before{ content: "\e054"; }
.#{$entypo-css-prefix}-newspaper:before{ content: "\e055"; }
.#{$entypo-css-prefix}-note:before{ content: "\e056"; }
.#{$entypo-css-prefix}-note-beamed:before{ content: "\e057"; }
.#{$entypo-css-prefix}-palette:before{ content: "\e058"; }
.#{$entypo-css-prefix}-paper-plane:before{ content: "\e059"; }
.#{$entypo-css-prefix}-pause:before{ content: "\e05a"; }
.#{$entypo-css-prefix}-phone:before{ content: "\e05b"; }
.#{$entypo-css-prefix}-picasa:before{ content: "\e05c"; }
.#{$entypo-css-prefix}-picture:before{ content: "\e05d"; }
.#{$entypo-css-prefix}-pinterest:before{ content: "\e05e"; }
.#{$entypo-css-prefix}-pinterest-circled:before{ content: "\e05f"; }
.#{$entypo-css-prefix}-play:before{ content: "\e060"; }
.#{$entypo-css-prefix}-plus:before{ content: "\e061"; }
.#{$entypo-css-prefix}-plus-circled:before{ content: "\e062"; }
.#{$entypo-css-prefix}-plus-squared:before{ content: "\e063"; }
.#{$entypo-css-prefix}-popup:before{ content: "\e064"; }
.#{$entypo-css-prefix}-print:before{ content: "\e065"; }
.#{$entypo-css-prefix}-progress-2:before{ content: "\e066"; }
.#{$entypo-css-prefix}-progress-3:before{ content: "\e067"; }
.#{$entypo-css-prefix}-publish:before{ content: "\e068"; }
.#{$entypo-css-prefix}-qq:before{ content: "\e069"; }
.#{$entypo-css-prefix}-quote:before{ content: "\e06a"; }
.#{$entypo-css-prefix}-rdio:before{ content: "\e06b"; }
.#{$entypo-css-prefix}-rdio-circled:before{ content: "\e06c"; }
.#{$entypo-css-prefix}-record:before{ content: "\e06d"; }
.#{$entypo-css-prefix}-renren:before{ content: "\e06e"; }
.#{$entypo-css-prefix}-reply:before{ content: "\e06f"; }
.#{$entypo-css-prefix}-reply-all:before{ content: "\e070"; }
.#{$entypo-css-prefix}-retweet:before{ content: "\e071"; }
.#{$entypo-css-prefix}-right:before{ content: "\e072"; }
.#{$entypo-css-prefix}-right-bold:before{ content: "\e073"; }
.#{$entypo-css-prefix}-right-circled:before{ content: "\e074"; }
.#{$entypo-css-prefix}-right-dir:before{ content: "\e075"; }
.#{$entypo-css-prefix}-right-open:before{ content: "\e076"; }
.#{$entypo-css-prefix}-right-open-big:before{ content: "\e077"; }
.#{$entypo-css-prefix}-right-open-mini:before{ content: "\e078"; }
.#{$entypo-css-prefix}-right-thin:before{ content: "\e079"; }
.#{$entypo-css-prefix}-rocket:before{ content: "\e07a"; }
.#{$entypo-css-prefix}-rss:before{ content: "\e07b"; }
.#{$entypo-css-prefix}-shareable:before{ content: "\e07c"; }
.#{$entypo-css-prefix}-shuffle:before{ content: "\e07d"; }
.#{$entypo-css-prefix}-signal:before{ content: "\e07e"; }
.#{$entypo-css-prefix}-sina-weibo:before{ content: "\e07f"; }
.#{$entypo-css-prefix}-skype:before{ content: "\e080"; }
.#{$entypo-css-prefix}-skype-circled:before{ content: "\e081"; }
.#{$entypo-css-prefix}-smashing:before{ content: "\e082"; }
.#{$entypo-css-prefix}-sound:before{ content: "\e083"; }
.#{$entypo-css-prefix}-soundcloud:before{ content: "\e084"; }
.#{$entypo-css-prefix}-spotify:before{ content: "\e085"; }
.#{$entypo-css-prefix}-spotify-circled:before{ content: "\e086"; }
.#{$entypo-css-prefix}-stop:before{ content: "\e087"; }
.#{$entypo-css-prefix}-stumbleupon:before{ content: "\e088"; }
.#{$entypo-css-prefix}-stumbleupon-circled:before{ content: "\e089"; }
.#{$entypo-css-prefix}-suitcase:before{ content: "\e08a"; }
.#{$entypo-css-prefix}-sweden:before{ content: "\e08b"; }
.#{$entypo-css-prefix}-switch:before{ content: "\e08c"; }
.#{$entypo-css-prefix}-tag:before{ content: "\e08d"; }
.#{$entypo-css-prefix}-tape:before{ content: "\e08e"; }
.#{$entypo-css-prefix}-target:before{ content: "\e08f"; }
.#{$entypo-css-prefix}-thermometer:before{ content: "\e090"; }
.#{$entypo-css-prefix}-thumbs-down:before{ content: "\e091"; }
.#{$entypo-css-prefix}-thumbs-up:before{ content: "\e092"; }
.#{$entypo-css-prefix}-to-end:before{ content: "\e093"; }
.#{$entypo-css-prefix}-to-start:before{ content: "\e094"; }
.#{$entypo-css-prefix}-tools:before{ content: "\e095"; }
.#{$entypo-css-prefix}-traffic-cone:before{ content: "\e096"; }
.#{$entypo-css-prefix}-trash:before{ content: "\e097"; }
.#{$entypo-css-prefix}-trophy:before{ content: "\e098"; }
.#{$entypo-css-prefix}-tumblr:before{ content: "\e099"; }
.#{$entypo-css-prefix}-tumblr-circled:before{ content: "\e09a"; }
.#{$entypo-css-prefix}-twitter:before{ content: "\e09b"; }
.#{$entypo-css-prefix}-twitter-circled:before{ content: "\e09c"; }
.#{$entypo-css-prefix}-up:before{ content: "\e09d"; }
.#{$entypo-css-prefix}-up-bold:before{ content: "\e09e"; }
.#{$entypo-css-prefix}-up-dir:before{ content: "\e09f"; }
.#{$entypo-css-prefix}-up-open:before{ content: "\e0a0"; }
.#{$entypo-css-prefix}-up-open-big:before{ content: "\e0a1"; }
.#{$entypo-css-prefix}-up-open-mini:before{ content: "\e0a2"; }
.#{$entypo-css-prefix}-up-thin:before{ content: "\e0a3"; }
.#{$entypo-css-prefix}-upload:before{ content: "\e0a4"; }
.#{$entypo-css-prefix}-upload-cloud:before{ content: "\e0a5"; }
.#{$entypo-css-prefix}-user:before{ content: "\e0a6"; }
.#{$entypo-css-prefix}-user-add:before{ content: "\e0a7"; }
.#{$entypo-css-prefix}-users:before{ content: "\e0a8"; }
.#{$entypo-css-prefix}-vcard:before{ content: "\e0a9"; }
.#{$entypo-css-prefix}-video:before{ content: "\e0aa"; }
.#{$entypo-css-prefix}-vimeo-circled:before{ content: "\e0ab"; }
.#{$entypo-css-prefix}-vkontakte:before{ content: "\e0ac"; }
.#{$entypo-css-prefix}-volume:before{ content: "\e0ad"; }
.#{$entypo-css-prefix}-water:before{ content: "\e0ae"; }
.#{$entypo-css-prefix}-window:before{ content: "\e0af"; }
.#{$entypo-css-prefix}-pencil:before{ content: "\e0b0"; }
.#{$entypo-css-prefix}-progress-1:before{ content: "\e0b1"; }
.#{$entypo-css-prefix}-resize-small:before{ content: "\e0b2"; }
.#{$entypo-css-prefix}-share:before{ content: "\e0b3"; }
.#{$entypo-css-prefix}-star-empty:before{ content: "\e0b4"; }
.#{$entypo-css-prefix}-ticket:before{ content: "\e0b5"; }
.#{$entypo-css-prefix}-up-circled:before{ content: "\e0b6"; }
.#{$entypo-css-prefix}-vimeo:before{ content: "\e0b7"; }
.#{$entypo-css-prefix}-paypal:before{ content: "\e0b8"; }
.#{$entypo-css-prefix}-progress-0:before{ content: "\e0b9"; }
.#{$entypo-css-prefix}-resize-full:before{ content: "\e0ba"; }
.#{$entypo-css-prefix}-search:before{ content: "\e0bb"; }
.#{$entypo-css-prefix}-star:before{ content: "\e0bc"; }
.#{$entypo-css-prefix}-address:before{ content: "\e0bd"; }
