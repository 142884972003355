
$font-path: '/dist/fonts/' !default;

@font-face {
	font-family: 'Mecum';
	src: url('#{$font-path}mecumbook.eot');
	src: url('#{$font-path}mecumbook.eot?#iefix') format('embedded-opentype'),
		url('#{$font-path}mecumbook.woff') format('woff'),
		url('#{$font-path}mecumbook.ttf') format('truetype'),
		url('#{$font-path}mecumbook.svg#wf') format('svg');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Mecum';
	src: url('#{$font-path}mecumbold.eot');
	src: url('#{$font-path}mecumbold.eot?#iefix') format('embedded-opentype'),
		url('#{$font-path}mecumbold.woff') format('woff'),
		url('#{$font-path}mecumbold.ttf') format('truetype'),
		url('#{$font-path}mecumbold.svg#wf') format('svg');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Mecum';
	src: url('#{$font-path}mecumblack.eot');
	src: url('#{$font-path}mecumblack.eot?#iefix') format('embedded-opentype'),
		url('#{$font-path}mecumblack.woff') format('woff'),
		url('#{$font-path}mecumblack.ttf') format('truetype'),
		url('#{$font-path}mecumblack.svg#wf') format('svg');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Mecum';
	src: url('#{$font-path}mecummedium.eot');
	src: url('#{$font-path}mecummedium.eot?#iefix') format('embedded-opentype'),
		url('#{$font-path}mecummedium.woff') format('woff'),
		url('#{$font-path}mecummedium.ttf') format('truetype'),
		url('#{$font-path}mecummedium.svg#wf') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Mecum';
	src: url('#{$font-path}mecumlight.eot');
	src: url('#{$font-path}mecumlight.eot?#iefix') format('embedded-opentype'),
		url('#{$font-path}mecumlight.woff') format('woff'),
		url('#{$font-path}mecumlight.ttf') format('truetype'),
		url('#{$font-path}mecumlight.svg#wf') format('svg');
	font-weight: 300;
	font-style: normal;
}

/*!
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
with any questions regarding Web Fonts:  http://www.linotype.com
*/

/*@import url("http://fast.fonts.net/lt/1.css?apiType=css&c=bb4e1bf1-cff4-4595-9d68-3ea6858d2811&fontids=1475958,1475970,1475982,1476006,1476018");*/

@font-face{
	font-family: "Univers";
	src: url("#{$font-path}univers.eot?#iefix");
	src: url("#{$font-path}univers.eot?#iefix") format("eot"),
		url("#{$font-path}univers.woff2") format("woff2"),
		url("#{$font-path}univers.woff") format("woff"),
		url("#{$font-path}univers.ttf") format("truetype"),
		url("#{$font-path}univers.svg#d7d2f6ed-1dde-4178-9353-bdd0821e77cb") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face{
	font-family: "Univers";
	src: url("#{$font-path}univers-bold.eot?#iefix");
	src: url("#{$font-path}univers-bold.eot?#iefix") format("eot"),
		url("#{$font-path}univers-bold.woff2") format("woff2"),
		url("#{$font-path}univers-bold.woff") format("woff"),
		url("#{$font-path}univers-bold.ttf") format("truetype"),
		url("#{$font-path}univers-bold.svg#456f8353-6dc4-4323-bae1-c3cb5109d01f") format("svg");
	font-weight: 700;
	font-style: normal;
}
@font-face{
	font-family:"Univers";
	src: url("#{$font-path}univers-black.eot?#iefix");
	src: url("#{$font-path}univers-black.eot?#iefix") format("eot"),
		url("#{$font-path}univers-black.woff2") format("woff2"),
		url("#{$font-path}univers-black.woff") format("woff"),
		url("#{$font-path}univers-black.ttf") format("truetype"),
		url("#{$font-path}univers-black.svg#2a1a5257-534c-49fe-be09-285c42a239aa") format("svg");
	font-weight: 900;
	font-style: normal;
}
@font-face{
	font-family:"UniversCond";
	src: url("#{$font-path}univers-condensed.eot?#iefix");
	src: url("#{$font-path}univers-condensed.eot?#iefix") format("eot"),
		url("#{$font-path}univers-condensed.woff2") format("woff2"),
		url("#{$font-path}univers-condensed.woff") format("woff"),
		url("#{$font-path}univers-condensed.ttf") format("truetype"),
		url("#{$font-path}univers-condensed.svg#f9888104-9fa3-487e-93a6-d692c45051fb") format("svg");
	font-weight: normal;
	font-style: normal;

}
@font-face{
	font-family: "UniversCond";
	src: url("#{$font-path}univers-condensed-bold.eot?#iefix");
	src: url("#{$font-path}univers-condensed-bold.eot?#iefix") format("eot"),
		url("#{$font-path}univers-condensed-bold.woff2") format("woff2"),
		url("#{$font-path}univers-condensed-bold.woff") format("woff"),
		url("#{$font-path}univers-condensed-bold.ttf") format("truetype"),
		url("#{$font-path}univers-condensed-bold.svg#1d9abff1-1aad-4151-a7ce-8d0af5543de8") format("svg");
	font-weight: 700;
	font-style: normal;
}
