.alert-icon {
	position: relative;
	padding-left: 55px;

	.fa {
		position: absolute;
		top: 50%;
		margin-top: -15px;
		left: 15px;
	}
}
