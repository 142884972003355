$brand-primary: #ed1c22;
$icon-font-path: '/dist/fonts/';
@import "../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap";

@import "partials/fonts";
@import "partials/mixins";
@import "partials/icons";
@import "partials/helpers";

@import "partials/public/variables";
@import "partials/public/layout";
@import "partials/public/headings";
@import "partials/public/header";
@import "partials/public/buttons";
@import "partials/public/footer";
@import "partials/public/forms";
@import "partials/public/panels";
@import "partials/public/alerts";
@import "partials/public/registration";
@import "partials/public/wells";
@import "partials/public/wizard";
@import "partials/public/form-validation";

body {
	background: #575757;
	padding-top: 50px;

	&.page-error {
		background-color: #333;
		color: #aaabae;
		text-align: center;
		margin-top: 150px;

		h1, h2 {
			color: #fff;
		}
	}
}
